// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "_variables";

.bold {
    font-weight: bold;
}

body {
    font-family: "Open Sans", sans-serif;
}

//I have no clue what this is
.fa-btn {
    margin-right: 6px;
}

.show-pdf {
    height: 60vh;
    width: 100%;
}

.show-loop,
.hide-me {
    display: none;
}

.show-loop.hide-me {
    display: block;
}

.ml-3 {
    margin-left: 10px;
}

.mr-3 {
    margin-right: 10px;
}

.mt-5 {
    margin-top: 30px !important;
}

//Overwrite ALL THE BUTTONS!
a.btn:not(.btn-link), button.btn, input[type=button].btn, input[type=submit].btn {
    background-color: $aqua !important;
    border: none !important;
    color: white !important;
}

a {
    color: $aqua !important;
}

span.error {
    color: red;
    font-style: italic;
}

.box-title {
    a {
        color: $darkPurple !important;
        text-decoration: underline;
    }
}

h1, h2, h3, h4, h5, h6, .panel-heading {
    text-transform: uppercase;
    color: $darkPurple;
    font-weight: 600;
}

.panel-heading {
    color: white !important;
    background-color: $darkPurple !important;
    height: 52px;
    span {
        vertical-align: middle;
        vertical-align: -moz-middle-with-baseline;
        vertical-align: -webkit-baseline-middle;
    }
}

//Top nav bar thingy
.navbar-static-top {
    background-color: white !important;
    border-bottom: 1px solid #cccccc !important;
    .navbar-nav {
        padding-top: 35px;
        // Top menu nav
        a {
            font-weight: 600;
            color: #333 !important;
            text-transform: uppercase;
        }
    }

    //Logo in the top left
    a.navbar-brand {
        height: auto !important;
        img {
            height: 55px;
        }
    }

    .navbar-toggle {
        border: none;
        background-color: transparent !important;
        position: relative;
        transform: translateY(100%);
        -webkit-transform: translateY(100%);
    }

    @media (max-width: 767px) {
        #app-navbar-collapse {
            background-color: #eee;
        }
        .navbar-nav {
            padding-top: 0 !important;
        }
    }
}

//Overwriting bootstrap nav tabs
.nav-tabs li {
    a {
        background-color: #eee !important;
        color: #999 !important;
        font-weight: 600;
        padding: 10px 6px !important;
    }
    &.active a {
        color: white !important;
        background-color: $aqua !important;
    }
}

//Make the tables have padding on the top
div.table-responsive {
    padding-top: 25px;
    margin-top: 25px;
}

//Overwriting bootstrap accordion stuffs
div.panel-group.visible-xs {
    div.panel {
        div.panel-heading {
            padding: 0 !important;
        }
        h4 {
            height: 100%;
            width: 100%;
            a {
                height: 100%;
                width: 100%;
                text-align: left;
                display: block;
                background-color: #eee !important;
                color: #999 !important;
                text-transform: none !important;
                padding: 17px 15px 0;
                font-size: 14px !important;
            }
            a:not(.collapsed) {
                background-color: $aqua !important;
                color: white !important;
            }
            a:hover, a:focus {
                text-decoration: none !important;
            }
            a:focus {
                outline: none !important;
            }
        }
    }
}

#summary table th {
    width: 35%;
}

.fa-trash, .fa-edit {
    color: $aqua;
    cursor: pointer;
}

.fa-info-circle {
    color: $darkPurple;
}

/* Steam uses percent inputs */
div.col-sm-6.col-sm-offset-6 {
    padding: 5px 0;
}

.red, .red + span {
    border-color: red !important;
}

.green, .green + span {
    border-color: green !important;
}

/* Safari sux */
input[type=text], textarea, select, input[type=email], input[type=tel], input[type=url] {
    -webkit-appearance: none;
}

select:not(:disabled):not([multiple]) {
    -moz-appearance: none;
    appearance: none;
    background: url('/images/dd-arrow.png') no-repeat 96% 12px !important;
    background-size: 14px auto !important;
}

select:not(:disabled)::-ms-expand {
    display: none;
}

.selectize-control.single .selectize-input::after {
    right: 4% !important;
}

div.form-control.selectize-control {
    height: 34px !important;
}

.modal-header {
    background-color: $darkPurple !important;
    .modal-title, button {
        color: white !important;
    }
}

.modal-button {
    margin-top: -20px;
    margin-bottom: 20px;
}

.required-input:after {
    content: '*';
    color: $darkPurple;
    vertical-align: top;
    margin-left: 5px;
}

.label-primary {
    background-color: $aqua !important;
}

/* Overwriting selectize junk for disabled junk */
div.selectize-control div.selectize-input.disabled {
    cursor: not-allowed !important;
    background-color: #EBEBE4 !important;
    opacity: 1 !important;
}

.nav-buttons {
    padding-top: 15px;
}

table.double-header {
    th {
        border-top: none !important;
        border-bottom: none !important;
    }
}

#submit-current-record, #review-current-record {
    margin-left: 5px;
}

.sidenote {
    margin-bottom: 15px;
}

@media print {
    a[href]:after {
        content: none !important;
    }
}
